<template>
    <div class="x-wrap x-wrap--full" v-if="hasExceededData">
        <user-group-tips></user-group-tips>
    </div>
    <div class="x-wrap x-wrap--full" :class="$style.overview" v-else>
        <div :class="$style.left">
            <div :class="$style.title">
                <h3>
                    <span>{{ $t('users.overview_total_title') }}</span>
                    <el-tooltip :content="$t('annotation.user_group_6')">
                        <pt-icon
                            icon="pt-icon--help-new"
                            :icon-style="{
                                width: '16px',
                                height: '16px',
                                fill: '#5E6C84',
                                margin: '0 0 10px 6px'
                            }"
                        ></pt-icon>
                    </el-tooltip>
                </h3>
            </div>
            <div
                :class="[$style.box, $style.data]"
                v-loading="totals.loading"
                element-loading-background="transparent"
                :element-loading-text="totals.loadingText"
            >
                <template v-if="!totals.loading">
                    <div
                        v-if="totals.queryStatus === 'error'"
                        :class="$style.noData"
                        v-html="$t('error.common_500')"
                    ></div>
                    <div :class="$style['data-container']" v-else-if="totals.data">
                        <h3>{{ totals.data.filterUsers }}</h3>
                        <span>{{ $t('users.overview_total', { total: totals.data.rate }) }}</span>
                    </div>
                </template>
            </div>
            <div :class="$style.title">
                <h3>
                    <span>{{ $t('users.overview_active_users') }}</span>
                    <el-tooltip :content="$t('annotation.user_group_8')">
                        <pt-icon
                            icon="pt-icon--help-new"
                            :icon-style="{
                                width: '16px',
                                height: '16px',
                                fill: '#5E6C84',
                                margin: '0 0 10px 6px'
                            }"
                        ></pt-icon>
                    </el-tooltip>
                </h3>
                <pt-button type="outline" color="black" size="small" @click="handleViewUserReport">{{
                    $t('users.overview_view_report_btn')
                }}</pt-button>
            </div>
            <div
                :class="[$style.box, $style.chart, totals.queryStatus === 'error' && $style.noData]"
                v-loading="chart.loading"
                element-loading-background="transparent"
                :element-loading-text="chart.loadingText"
            >
                <template v-if="!chart.loading">
                    <div v-if="totals.queryStatus === 'error'" v-html="$t('error.common_500')"></div>
                    <template v-else>
                        <div :class="$style['chart-data']">{{ chartData.count }}</div>
                        <div v-if="chartData.rate !== '--'" :class="[$style['chart-rate'], $style[chartData.trend]]">
                            <pt-icon
                                :icon="`pt-icon--arrow-${chartData.trend}`"
                                :icon-style="{
                                    width: '16px',
                                    height: '16px'
                                }"
                            ></pt-icon>
                            <span>{{ chartData.rate }}%</span>
                        </div>
                        <div :class="$style['chart-container']" v-if="chart.chartData">
                            <pt-highcharts
                                chart-type="line"
                                :settings="chart.chartSettings"
                                :series="chart.chartData"
                            ></pt-highcharts>
                        </div>
                    </template>
                </template>
            </div>

            <!--
            <div :class="$style.title">
                <h3>{{ $t('users.overview_goal') }}</h3>
            </div>
            <div :class="[$style.box, $style.goal]">
                <div :class="$style['goal-container']">
                    <p>{{ $t('users.overview_goal_tips') }}</p>
                </div>
            </div>
            -->
        </div>
        <div :class="$style.right">
            <div :class="$style.title">
                <h3>
                    <span>{{ $t('users.overview_recent') }}</span>
                    <el-tooltip :content="$t('annotation.user_group_7')">
                        <pt-icon
                            icon="pt-icon--help-new"
                            :icon-style="{
                                width: '16px',
                                height: '16px',
                                fill: '#5E6C84',
                                margin: '0 0 10px 6px'
                            }"
                        ></pt-icon>
                    </el-tooltip>
                </h3>
                <pt-button type="outline" color="black" size="small" @click="handleViewUserList">{{
                    $t('users.overview_view_list_btn')
                }}</pt-button>
            </div>
            <div
                :class="[
                    $style.box,
                    $style.setting,
                    userInfo.area.toLocaleUpperCase() === 'EN' ? $style.en : $style.jp
                ]"
                v-if="visibleSetting"
            >
                <p>{{ $t('users.overview_recent_tips') }}</p>
                <div :class="$style['setting-btnGroup']">
                    <router-link :to="{ name: 'IdentifyUsers', query: { redirect: $route.fullPath } }">
                        <pt-button>{{ $t('users.overview_setting_btn') }}</pt-button>
                    </router-link>
                </div>
            </div>
            <div
                :class="$style.list"
                v-loading="userList.loading"
                element-loading-background="transparent"
                :element-loading-text="userList.loadingText"
            >
                <template v-if="!userList.loading">
                    <div
                        v-if="userList.queryStatus === 'error'"
                        :class="$style.noData"
                        v-html="$t('error.common_500')"
                    ></div>
                    <template v-else-if="userList.showData && userList.showData.length > 0">
                        <div :class="$style.user" v-for="(user, $index) in userList.showData" :key="$index">
                            <div :class="$style['user-icon']" :style="user.iconStyle">
                                {{ getIcon(user.email || user.name) }}
                            </div>
                            <div :class="$style['user-info']">
                                <div :class="$style['user-label']">
                                    <h4>{{ user.email || user.name || $t('users.overview_anonymous') }}</h4>
                                    <h5>{{ user['total sessions'] }} {{ $t('users.overview_sessions') }}</h5>
                                </div>
                                <div :class="$style['user-label']">
                                    <span
                                        >{{ $t('users.overview_last_active') }}{{ user.active }} • {{ user.city }}</span
                                    >
                                </div>
                            </div>
                        </div>
                    </template>
                    <div v-else :class="$style.noData">{{ $t('users.users_report_no_data') }}</div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { mapGetters, mapState } from 'vuex';
import { debounce } from 'throttle-debounce';
import usersApis from '@/apis/users.apis';
import numberUtils from '@/common/utils/number.utils';
import dateUtils from '@/common/utils/date.utils';
import commonUtils from '@/common/utils/common.utils';
import UserGroupTips from './UserGroupTips';

var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

export default {
    name: 'UsersSettingOverview',

    data() {
        const unkoneColor = '#8993A4';
        const colors = ['#4589FF', '#66D7AC', '#F0C800', '#FF8475'];
        return {
            colors,
            unkoneColor,
            hasExceededData: false,
            queryDataExceededErrorCode: 'QUERY_DATA_EXCEEDED',
            visibleStates: {
                setting: true
            },

            date: {
                startTime: dateUtils.getMomentTime({
                    num: -6,
                    type: 'day',
                    isStart: true,
                    timezone: this.timeZone
                }),
                endTime: dateUtils.getMomentTime({
                    num: 0,
                    type: 'day',
                    isStart: true,
                    timezone: this.timeZone
                })
            },

            totals: {
                data: null,
                showData: {},
                loading: true,
                loadingText: '',
                loadingTextStore: commonUtils.loadingTextStore(),
                queryStatus: 'start', //start/pending/success/error
                requestId: 'FETCH_USERS_OVERVIEW_TOTALS_ID'
            },

            chart: {
                data: null,
                loading: true,
                loadingText: '',
                loadingTextStore: commonUtils.loadingTextStore(),
                queryStatus: 'start', //start/pending/success/error
                requestId: 'FETCH_USERS_OVERVIEW_CHART_ID',
                chartData: null,
                chartSettings: {
                    chart: {
                        height: 126,
                        padding: 0,
                        marginTop: 4,
                        marginLeft: 4,
                        marginRight: 4,
                        marginBottom: 8
                    },
                    xAxis: {
                        visible: false,
                        type: 'linear'
                    },
                    yAxis: {
                        visible: false
                    },
                    legend: {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            marker: {
                                states: {
                                    hover: {
                                        lineWidth: 3,
                                        lineWidthPlus: 3,
                                        radius: 6,
                                        radiusPlus: 6
                                    }
                                }
                            }
                        }
                    },
                    tooltip: {
                        backgroundColor: 'transparent',
                        outside: false,
                        formatter: function() {
                            const points = this.points || [this.point];
                            const { x, y, key } = points[0] || {};
                            return (
                                (y !== undefined &&
                                    `<div class="overview-chart--tooltip">${key}: <span>${numberUtils.formatNumber(
                                        y
                                    )}</span></div>`) ||
                                ''
                            );
                        }
                    }
                }
            },

            userList: {
                data: null,
                showData: [],
                loading: true,
                loadingText: '',
                loadingTextStore: commonUtils.loadingTextStore(),
                itemLen: 0, //已显示条数
                queryStatus: 'start', //start/pending/success/error
                requestId: 'FETCH_USERS_OVERVIEW_LIST_ID',
                properties: [
                    {
                        key: 'name',
                        type: 'CUSTOM',
                        patternType: 'STRING'
                    },
                    {
                        key: 'email',
                        type: 'CUSTOM',
                        patternType: 'STRING'
                    },
                    {
                        key: 'city',
                        type: 'STATIC',
                        patternType: 'STRING'
                    },
                    {
                        key: 'last active',
                        type: 'STATIC',
                        patternType: 'TIME'
                    },
                    {
                        key: 'total sessions',
                        type: 'STATIC',
                        patternType: 'NUMBER'
                    }
                ]
            }
        };
    },

    computed: {
        ...mapGetters('main', ['profileInfo', 'userInfo']),
        ...mapGetters('users', ['usersFilter']),
        ...mapState('users', ['userProperties']),

        chartData() {
            const { currentResult, lastResult } = this.chart.data || {
                currentResult: { count: { userCount: 0 } },
                lastResult: { count: { userCount: 0 } }
            };
            const currCount = currentResult.count.userCount || 0;
            const lastCount = lastResult.count.userCount || 0;
            return {
                count: numberUtils.formatNumber(currCount),
                trend: currCount >= lastCount ? 'up' : 'down',
                rate: lastCount === 0 ? '--' : ((Math.abs(lastCount - currCount) / lastCount) * 100).toFixed(2)
            };
        },

        visibleSetting() {
            // 当没有任何用户自定义属性(sourceLists = ['custom', 'engage', 'api'])。则提示
            return (this.userProperties || []).every(property => {
                const sourceLists = property.sourceLists || [];
                return sourceLists.length === 1 && ['default', 'static'].includes(sourceLists[0]);
            });
        }
    },

    created() {
        this.$eventBus.$on('mainScrollReachEndY', this.updateShowUserList, false);
    },

    beforeDestroy() {
        this.$eventBus.$off('mainScrollReachEndY', this.updateShowUserList, false);
        this.chart.loadingTextStore.stop();
        this.totals.loadingTextStore.stop();
        this.userList.loadingTextStore.stop();
    },

    mounted() {
        this.queryDebounce = debounce(200, false, () => {
            this.fetchAllData();
        });
        this.queryDebounce();
    },

    methods: {
        fetchAllData() {
            this.fetchTotalData();
            this.fetchChartData();
            this.fetchUserList();
        },

        cancelAllFetch(errorCode) {
            errorCode === this.queryDataExceededErrorCode &&
                (['totals', 'chart', 'userList'].forEach(key => axios.cancel(this[key].requestId)),
                (this.hasExceededData = true));
        },

        requestStandardData(funName, requestId) {
            const { sid, timeZone } = this.profileInfo;
            const { startTime, endTime, timeType } = this.date;
            return usersApis.standardData(
                null,
                {
                    where: {
                        sid,
                        funName,
                        startTime,
                        endTime,
                        timeModel: 'day',
                        timeType,
                        timeZone,
                        audienceFilter: this.usersFilter,
                        sequentialFlag: 1,
                        filter: {}
                    }
                },
                { requestId }
            );
        },

        async fetchTotalData() {
            this.totals.loading = true;
            this.totals.data = null;
            this.totals.queryStatus = 'pending';
            this.totals.loadingTextStore.start(() => {
                this.totals.loadingText = this.$t('users.query_data_large_loading');
            });
            axios.cancel(this.totals.requestId);

            const [err, standardData] = await commonUtils.awaitWrap(
                this.requestStandardData('overviewMetricAll', this.totals.requestId)
            );

            if (err || !standardData) {
                this.totals.queryStatus = 'error';
                this.totals.data = null;
                this.cancelAllFetch(err?.code);
            } else if (!axios.isCancel(standardData)) {
                const { allUsers, filterUsers } = standardData.currentResult || {};
                this.totals.data = {
                    allUsers: (allUsers && numberUtils.formatNumber(allUsers)) || 0,
                    filterUsers: (filterUsers && numberUtils.formatNumber(filterUsers)) || 0,
                    rate: (allUsers && filterUsers && ((filterUsers / allUsers) * 100).toFixed(2) + '%') || '--'
                };
                this.totals.queryStatus = 'success';
            }
            this.totals.loading = false;
            this.totals.loadingTextStore.stop();
        },

        async fetchChartData() {
            this.chart.loading = true;
            this.chart.data = null;
            this.chart.queryStatus = 'pending';
            this.chart.loadingTextStore.start(() => {
                this.chart.loadingText = this.$t('users.query_data_large_loading');
            });
            axios.cancel(this.chart.requestId);

            const [err, standardData] = await commonUtils.awaitWrap(
                this.requestStandardData('userCurve', this.chart.requestId)
            );

            if (err || !standardData) {
                this.chart.queryStatus = 'error';
                this.cancelAllFetch(err?.code);
            } else if (!axios.isCancel(standardData)) {
                this.chart.data = standardData;
                this.setChartData(standardData.currentResult.list);
                this.chart.queryStatus = 'success';
            }
            this.chart.loading = false;
            this.chart.loadingTextStore.stop();
        },

        async fetchUserList() {
            this.userList.loading = true;
            this.userList.queryStatus = 'pending';
            this.userList.loadingTextStore.start(() => {
                this.userList.loadingText = this.$t('users.query_data_large_loading');
            });
            axios.cancel(this.userList.requestId);

            const [err, detailData] = await commonUtils.awaitWrap(
                usersApis.detailData(
                    null,
                    {
                        where: {
                            sid: this.profileInfo.sid,
                            funName: 'userList',
                            audienceFilter: this.usersFilter,
                            timeZone: this.profileInfo.timeZone,
                            limit: 20,
                            properties: this.userList.properties,
                            sort: 'total sessions'
                        }
                    },
                    { requestId: this.userList.requestId }
                )
            );

            if (err || !detailData) {
                this.userList.data = [];
                this.userList.showData = [];
                this.userList.queryStatus = 'error';
                this.cancelAllFetch(err?.code);
            } else if (!axios.isCancel(detailData)) {
                this.userList.data = (detailData.user_list || []).map(item => {
                    const lastActiveTime = item['last active'];
                    item.active = '--';
                    // 时间戳类型
                    if (lastActiveTime && lastActiveTime.length === 13) {
                        const date = new Date(Number(lastActiveTime));
                        !Number.isNaN(date.getTime()) && (item.active = dayjs(date).fromNow());
                    }
                    item.iconStyle = this.getStyle(item.email || item.name);
                    return item;
                });
                this.updateShowUserList(true);
                this.userList.queryStatus = 'success';
            }
            this.userList.loading = false;
            this.userList.loadingTextStore.stop();
        },

        handleSettingCloseClick() {},

        getIcon(email) {
            return email ? email[0].toLocaleUpperCase() : '?';
        },

        getStyle(email) {
            const index = Math.floor(Math.random() * this.colors.length);
            return {
                'background-color': email ? this.colors[index] : this.unkoneColor
            };
        },

        setChartData(dataList = []) {
            const { startTime, endTime } = this.date;
            const categories = dateUtils.getDays(startTime, endTime);
            const chartData = [dataList];
            this.chart.chartData = chartData.map((series, index) => {
                const seriesData = categories.map(categorie => {
                    const categorieInfo = series.find(item => item.key === categorie);
                    const y = categorieInfo ? categorieInfo.userCount : 0;
                    return {
                        name: categorie,
                        y
                    };
                });
                return {
                    name: this.$t('users.chart_user_active_trends'),
                    data: seriesData
                };
            });
        },

        updateShowUserList(reset = false) {
            reset && ((this.userList.itemLen = 0), (this.userList.showData = []));
            const { data } = this.userList;
            let itemLen = this.userList.itemLen + 5; //每次新增5条
            if (this.userList.itemLen === 0) {
                const docHeight = document.body.offsetHeight;
                const itemHeight = 125;
                const overviewLen = 1;
                const minLen = 7;
                itemLen = Math.max(minLen, parseInt(docHeight / itemHeight) + overviewLen);
            }
            itemLen = Math.min(itemLen, data.length);
            this.userList.showData.push(...data.slice(this.userList.itemLen, itemLen));
            this.userList.itemLen = itemLen;
        },

        updateVisibleState(key, value) {
            this.$set(this.visibleStates, key, value);
        },

        handleViewUserList() {
            this.$router.push({ name: 'UsersSettingList' });
        },

        handleViewUserReport() {
            this.$router.push({ name: 'UsersSettingReport' });
        }
    },

    watch: {
        usersFilter: {
            handler: function(newVal, oldVal) {
                this.hasExceededData = false;
                this.$route.name === 'UsersSettingOverview' && this.queryDebounce();
            },
            deep: true
        },

        $route(val) {
            const { name } = val;
            name === 'UsersSettingOverview' && this.queryDebounce();
        }
    },

    components: {
        UserGroupTips
    }
};
</script>

<style lang="scss" module>
@import '@/styles/import.scss';

:global {
    .overview-chart--tooltip {
        color: $pt-green-60;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        font-weight: 600;
    }
}

.overview {
    padding: 0 0 100px;
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(12, 1fr);

    .left {
        grid-column-start: span 5;
        margin-bottom: 40px;
    }

    .right {
        grid-column-start: span 7;

        .box {
            margin-bottom: 0 !important;

            & + .list {
                border-top: none !important;
            }
        }
    }

    .title {
        min-height: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 18px;

        h3 {
            color: $pt-black-600;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 22px;
            padding-right: 10px;
            white-space: nowrap;
        }

        a {
            color: $pt-black-600;
            font-size: 14px;
            letter-spacing: 0;
            text-align: center;
            height: 24px;
            border: 1px solid $pt-black-40;
            border-radius: 2px;
            background-color: $pt-white;
            padding: 0 10px;
            cursor: pointer;
            white-space: nowrap;

            &:hover {
                color: $pt-black-1000;
                background-color: $pt-black-40;
            }
        }
    }

    .box {
        box-sizing: border-box;
        border: 1px solid $pt-black-40;
        border-radius: 2px;
        background-color: $pt-white;

        &:not(:last-child) {
            margin-bottom: 40px;
        }
    }

    .data {
        height: 128px;
        padding: 0 28px;
        @extend %flex-center;

        &-container {
            @extend %flex-center;
            flex-direction: column;
        }

        h3 {
            width: 100%;
            color: $pt-green-60;
            font-size: 28px;
            letter-spacing: 0;
            line-height: 38px;
            text-align: center;
            border-bottom: 1px solid $pt-black-600;
            margin-bottom: 6px;
        }

        span {
            color: $pt-black-600;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 19px;
        }
    }

    .chart {
        height: 242px;
        color: $pt-black-600;
        padding: 20px 28px;
        display: flex;
        flex-direction: column;

        &.noData {
            justify-content: center;
            align-items: center;
        }

        &-data {
            font-size: 28px;
            letter-spacing: 0;
            line-height: 38px;
        }

        &-rate {
            margin-top: 8px;
            display: flex;
            align-items: center;

            &.up {
                svg {
                    fill: $pt-green-60;
                }
            }

            &.down {
                svg {
                    fill: $pt-danger;
                }
            }

            span {
                font-size: 20px;
                letter-spacing: 0;
                line-height: 27px;
                margin-left: 8px;
            }
        }

        &-container {
            flex: 1;
        }
    }

    .goal {
        height: 286px;
        border-radius: 2px;
        background-color: $pt-black-20;
        @extend %flex-center;

        &-container {
            width: 80%;
            max-width: 300px;
            height: 192px;
            background: url('~@assets/images/users/goal-coming.svg') no-repeat center top;
            background-size: 100%;
            position: relative;

            p {
                color: $pt-black-300;
                font-size: 13px;
                letter-spacing: 0;
                line-height: 20px;
                width: 40.33%;
                top: 28.12%;
                position: absolute;
                right: 5.33%;
            }
        }
    }

    .setting {
        min-height: 128px;
        border: 1px solid $pt-renubd-hover;
        border-radius: 2px;
        padding: 24px 30px;
        position: relative;
        padding-left: 188px;
        display: flex;
        flex-direction: column;
        &.jp {
            background: url('~@assets/images/setup/identify_users_code.svg') rgba(240, 200, 0, 0.2) no-repeat 20px
                center;
            background-size: 158px auto;
        }
        &.en {
            background: url('~@assets/images/setup/identify_users_code_en.svg') rgba(240, 200, 0, 0.2) no-repeat 20px
                center;
            background-size: 158px auto;
        }
        svg {
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 10px;
        }

        p {
            color: $pt-black-600;
            font-size: 13px;
            letter-spacing: 0;
            line-height: 16px;
        }

        &-btnGroup {
            display: flex;
            margin-top: 14px;
        }

        &-btnLeft {
            @include button(small, dark, true);
            font-weight: normal;
            margin-right: 16px;
        }
    }

    .list {
        border: 1px solid $pt-black-40;
        min-height: 128px;
        background: $pt-white;
        border-radius: 2px;

        .noData {
            text-align: center;
            padding-top: 50px;
        }
    }

    .user {
        padding: 26px 36px;
        height: 88px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $pt-black-40;
        &:hover {
            background-color: $pt-black-30;
        }

        &-icon {
            height: 32px;
            width: 32px;
            margin-right: 16px;
            border-radius: 50%;
            background-color: #66d7ac;
            color: $pt-white;
            font-size: 24px;
            letter-spacing: 0;
            line-height: 24px;
            @extend %flex-center;
        }

        &-info {
            flex: 1;
            overflow: hidden;
        }

        &-label {
            display: flex;
            justify-content: space-between;
            letter-spacing: 0;

            // @include media-sm {
            //     flex-direction: column;

            //     h4,
            //     h5,
            //     span {
            //         margin: 0 !important;
            //     }
            // }

            h4 {
                color: $pt-black-600;
                font-size: 14px;
                line-height: 20px;
                @extend %text-ellipsis;
            }

            h5 {
                font-size: 14px;
                margin-left: 12px;
                line-height: 20px;
                @extend %text-ellipsis;
            }

            span {
                color: $pt-black-90;
                font-size: 12px;
                line-height: 19px;
                @extend %text-ellipsis;

                &:not(:last-child) {
                    margin-right: 12px;
                }
            }
        }
    }
}
</style>
