var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasExceededData)?_c('div',{staticClass:"x-wrap x-wrap--full"},[_c('user-group-tips')],1):_c('div',{staticClass:"x-wrap x-wrap--full",class:_vm.$style.overview},[_c('div',{class:_vm.$style.left},[_c('div',{class:_vm.$style.title},[_c('h3',[_c('span',[_vm._v(_vm._s(_vm.$t('users.overview_total_title')))]),_c('el-tooltip',{attrs:{"content":_vm.$t('annotation.user_group_6')}},[_c('pt-icon',{attrs:{"icon":"pt-icon--help-new","icon-style":{
                            width: '16px',
                            height: '16px',
                            fill: '#5E6C84',
                            margin: '0 0 10px 6px'
                        }}})],1)],1)]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.totals.loading),expression:"totals.loading"}],class:[_vm.$style.box, _vm.$style.data],attrs:{"element-loading-background":"transparent","element-loading-text":_vm.totals.loadingText}},[(!_vm.totals.loading)?[(_vm.totals.queryStatus === 'error')?_c('div',{class:_vm.$style.noData,domProps:{"innerHTML":_vm._s(_vm.$t('error.common_500'))}}):(_vm.totals.data)?_c('div',{class:_vm.$style['data-container']},[_c('h3',[_vm._v(_vm._s(_vm.totals.data.filterUsers))]),_c('span',[_vm._v(_vm._s(_vm.$t('users.overview_total', { total: _vm.totals.data.rate })))])]):_vm._e()]:_vm._e()],2),_c('div',{class:_vm.$style.title},[_c('h3',[_c('span',[_vm._v(_vm._s(_vm.$t('users.overview_active_users')))]),_c('el-tooltip',{attrs:{"content":_vm.$t('annotation.user_group_8')}},[_c('pt-icon',{attrs:{"icon":"pt-icon--help-new","icon-style":{
                            width: '16px',
                            height: '16px',
                            fill: '#5E6C84',
                            margin: '0 0 10px 6px'
                        }}})],1)],1),_c('pt-button',{attrs:{"type":"outline","color":"black","size":"small"},on:{"click":_vm.handleViewUserReport}},[_vm._v(_vm._s(_vm.$t('users.overview_view_report_btn')))])],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.chart.loading),expression:"chart.loading"}],class:[_vm.$style.box, _vm.$style.chart, _vm.totals.queryStatus === 'error' && _vm.$style.noData],attrs:{"element-loading-background":"transparent","element-loading-text":_vm.chart.loadingText}},[(!_vm.chart.loading)?[(_vm.totals.queryStatus === 'error')?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('error.common_500'))}}):[_c('div',{class:_vm.$style['chart-data']},[_vm._v(_vm._s(_vm.chartData.count))]),(_vm.chartData.rate !== '--')?_c('div',{class:[_vm.$style['chart-rate'], _vm.$style[_vm.chartData.trend]]},[_c('pt-icon',{attrs:{"icon":("pt-icon--arrow-" + (_vm.chartData.trend)),"icon-style":{
                                width: '16px',
                                height: '16px'
                            }}}),_c('span',[_vm._v(_vm._s(_vm.chartData.rate)+"%")])],1):_vm._e(),(_vm.chart.chartData)?_c('div',{class:_vm.$style['chart-container']},[_c('pt-highcharts',{attrs:{"chart-type":"line","settings":_vm.chart.chartSettings,"series":_vm.chart.chartData}})],1):_vm._e()]]:_vm._e()],2)]),_c('div',{class:_vm.$style.right},[_c('div',{class:_vm.$style.title},[_c('h3',[_c('span',[_vm._v(_vm._s(_vm.$t('users.overview_recent')))]),_c('el-tooltip',{attrs:{"content":_vm.$t('annotation.user_group_7')}},[_c('pt-icon',{attrs:{"icon":"pt-icon--help-new","icon-style":{
                            width: '16px',
                            height: '16px',
                            fill: '#5E6C84',
                            margin: '0 0 10px 6px'
                        }}})],1)],1),_c('pt-button',{attrs:{"type":"outline","color":"black","size":"small"},on:{"click":_vm.handleViewUserList}},[_vm._v(_vm._s(_vm.$t('users.overview_view_list_btn')))])],1),(_vm.visibleSetting)?_c('div',{class:[
                _vm.$style.box,
                _vm.$style.setting,
                _vm.userInfo.area.toLocaleUpperCase() === 'EN' ? _vm.$style.en : _vm.$style.jp
            ]},[_c('p',[_vm._v(_vm._s(_vm.$t('users.overview_recent_tips')))]),_c('div',{class:_vm.$style['setting-btnGroup']},[_c('router-link',{attrs:{"to":{ name: 'IdentifyUsers', query: { redirect: _vm.$route.fullPath } }}},[_c('pt-button',[_vm._v(_vm._s(_vm.$t('users.overview_setting_btn')))])],1)],1)]):_vm._e(),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.userList.loading),expression:"userList.loading"}],class:_vm.$style.list,attrs:{"element-loading-background":"transparent","element-loading-text":_vm.userList.loadingText}},[(!_vm.userList.loading)?[(_vm.userList.queryStatus === 'error')?_c('div',{class:_vm.$style.noData,domProps:{"innerHTML":_vm._s(_vm.$t('error.common_500'))}}):(_vm.userList.showData && _vm.userList.showData.length > 0)?_vm._l((_vm.userList.showData),function(user,$index){return _c('div',{key:$index,class:_vm.$style.user},[_c('div',{class:_vm.$style['user-icon'],style:(user.iconStyle)},[_vm._v(" "+_vm._s(_vm.getIcon(user.email || user.name))+" ")]),_c('div',{class:_vm.$style['user-info']},[_c('div',{class:_vm.$style['user-label']},[_c('h4',[_vm._v(_vm._s(user.email || user.name || _vm.$t('users.overview_anonymous')))]),_c('h5',[_vm._v(_vm._s(user['total sessions'])+" "+_vm._s(_vm.$t('users.overview_sessions')))])]),_c('div',{class:_vm.$style['user-label']},[_c('span',[_vm._v(_vm._s(_vm.$t('users.overview_last_active'))+_vm._s(user.active)+" • "+_vm._s(user.city))])])])])}):_c('div',{class:_vm.$style.noData},[_vm._v(_vm._s(_vm.$t('users.users_report_no_data')))])]:_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }